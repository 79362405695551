import {StatisticsSectionV1} from "@commons/uinew/domaincomponent/statistics";
import React from "react";
import {LodashFillIns} from "@commons/lodash";
import {FormattedMessage, useIntl} from "react-intl";

export let LatvianStatisticsSection = ({buttonText, buttonLink}) => {
    let {formatMessage} = useIntl()

    return <StatisticsSectionV1
        text={<FormattedMessage id={"pages.home.stats.container.text"} />}
        ctaText={buttonText}
        ctaLink={buttonLink}
        items={LodashFillIns.times(4, (i) => ({
            title: formatMessage({id: `pages.home.stats.boxes.${i + 1}.title`}),
            text: formatMessage({id: `pages.home.stats.boxes.${i + 1}.text`})
        }))}
    />
}
